.footer {
    padding: 20px 0;
    width: 100%;
    z-index: 100;
    background-color: #2b3035;
    margin-top: 1rem;
    font-size: 0.75rem;

    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.footerIcons {
    gap: 2rem;
    display: flex;
    cursor: pointer;
}

.footerIcons>div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.footerIcons img {
    width: 1.2rem;
    height: 1.2rem;
}